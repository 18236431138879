@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css');

* {
  font-family: Pretendard, Montserrat, Noto Sans KR, Nanum Square, Malgun Gothic, Times New Roman, sans-serif;
	word-break: break-all;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f3efee;
}

a, .link {
	text-decoration: underline;
  text-decoration-style: dotted;
	color: black;
}

.App {
	border: 1px solid #862633;
  margin-top: 60px;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  padding: 25px;
  width: 70%;
  background-color: #ffffff;
}
