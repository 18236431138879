.loginButton {
  height: 50px;
}
.logoutButton {
  width: 80px;
  height: 30px;
  background-color: #f0f0f0;
  border-radius: 10px;
  border: none;
  font-weight: bold;
}
